export default
{
  noPhoto: require('../asset/no-photo.png'),

  logo5: require('../asset/logo5.png'),
  bgIcon: require('../asset/bg-icon.png'),
  dfIcon: require('../asset/df-icon.png'),
  bgIconSmall: require('../asset/bg-icon-small.png'),
  dfIconSmall: require('../asset/df-icon-small.png'),
};
