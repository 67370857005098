import React from 'react';
import StyledComponent from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { Form } from 'react-bootstrap';
import { AppText, Colors } from '../../constant';
import { ApiManager }  from '../../managers';
import { NotificationUtility } from '../../components/notifications';
import { Messages as MessageContainer } from '../../components/messages';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default class Messages extends React.Component
{
  _isMounted = false;

  // MARK: - Constructor
	constructor(props)
	{
		console.log('Messages()');
		super(props);
  }

  async componentDidMount()
  {
    console.log('Messages.componentDidMount()');
    this._isMounted = true;
  }

  render()
	{
    const userId = this.props.cookies.get('user')._id.toString();
    console.log('Messages.render()');
    return (
      <Paper>
        <MessageContainer
          updateMasterState={this.props.updateMasterState}
          showAlert={this.props.showAlert}
          siteManager={this.props.siteManager}
          user={this.props.cookies.get('user')}
          location={this.props.location}
        />
      </Paper>
    );
  }

}
