import React from 'react';

const Cms = React.lazy(() => import('./pages/cms/cms'));
const Home = React.lazy(() => import('./pages/home/home'));
const JobManager = React.lazy(() => import('./pages/jobManager/jobManager'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const ModelManager = React.lazy(() => import('./pages/modelManager/modelManager'));
const NoMatch = React.lazy(() => import('./pages/noMatch/noMatch'));
const PageController = React.lazy(() => import('./components/page/pageController'));
const Reports = React.lazy(() => import('./pages/reports/reports'));
const Users = React.lazy(() => import('./pages/users/users'));
const View = React.lazy(() => import('./pages/view/view'));
const ViewTos = React.lazy(() => import('./pages/tos/viewTos'));

const Routes = [
  /* PageController managed routes */
  {
    path: '/',
    name: '',
    component: PageController,
  },
  {
    path: '/accept-invite',
    name: 'Accept Invite',
    component: PageController,
  },
  {
    path: '/confirm-reset',
    name: 'Confirm Reset',
    component: PageController,
  },
  {
    path: '/login',
    name: 'Login',
    component: PageController,
  },
  {
    path: '/register',
    name: 'Register',
    component: PageController,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: PageController,
  },

  // Not page controller routes
  {
    path: '/cms',
    name: 'CMS',
    component: Cms,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: ({ cookies, updateMasterState, siteManager, history }) =>
    {
      return (
        <Logout
          cookies={cookies}
          updateMasterState={updateMasterState}
          siteManager={siteManager}
          history={history}
        />
      )
    },
  },
  {
    path: '/model-manager',
    name: 'Model Manager',
    component: ModelManager,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
  },
  {
    path: '/tos',
    name: 'Tos',
    component: ViewTos,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/view',
    name: 'View',
    component: View,
  },
  // This must be last
  {
    component: NoMatch,
  },
];

export default Routes;
