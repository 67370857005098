import React from 'react'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLink,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// Routes config
import Routes from '../../../routes'
import { Notifications } from '../../notifications';
import { Images } from '../../../constant';
import { Search } from '../../search';

/*import {
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks
}  from './index'
*/

const Layout2 = ({  updateMasterState,
                    history,
                    title,
                    navOnChange,
                    loggedIn,
                    cookies,
                    notificationManager,
                    siteManager,
                    forward,
                    isMobile,
                    component,
                    path,
                    navListIsShowing,
                    activeApp,
                    setActiveApp }) =>
{
  const user = cookies.get('user');
  if(!user)
  {
    return (<></>);
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={() =>
        {
          const isShowing = (navListIsShowing === 'responsive' || navListIsShowing === false) ? true : 'responsive';
          updateMasterState({ navListIsShowing: isShowing });
        }}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={() =>
        {
          const isShowing = (navListIsShowing === 'responsive' || navListIsShowing === true) ? false : 'responsive';
          updateMasterState({ navListIsShowing: isShowing });
        }}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img
          className="c-icon c-icon-custom-size"
          style={{height: '48px'}}
          src={Images.logo5}
        />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/home">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        {false &&
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/settings">Settings</CHeaderNavLink>
        </CHeaderNavItem>}
      </CHeaderNav>

      <CHeaderNav className="px-3">

        {/* Profile photo */}
        <CDropdown
          inNav
          className="c-header-nav-items mx-2"
          direction="down"
        >
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar">
              <CImg
                src={user && user.photo ? user.photo : Images.noPhoto}
                className="c-avatar-img"
                alt="User"
              />
            </div>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem>
              <CIcon name="cil-user" className="mfe-2" />
              {user.username}
            </CDropdownItem>
            <div onClick={() => forward('/logout')}>
              <CDropdownItem>
                <CIcon name="cil-lock-locked" className="mfe-2" />
                Logout
              </CDropdownItem>
            </div>
          </CDropdownMenu>
        </CDropdown>

      </CHeaderNav>

    </CHeader>
  )
}

export default Layout2
