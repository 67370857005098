import React from 'react';

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavItem,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { Images } from '../../../constant';

// sidebar nav config
const nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/home',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
    /*badge: {
      color: 'info',
      text: 'NEW',
    }*/
  },
  /* Users */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Users']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Users',
    to: '/users',
    icon: 'cil-user',
  },
  /* CMS */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['CMS']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Forms',
    to: '/cms',
    icon: 'cil-spreadsheet',
  },

  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
];

const Layout2 = ({  user,
                    name,
                    navOnChange,
                    isShowing,
                    active,
                    setIsShowing }) =>
{
  return (
    <CSidebar
      show={isShowing}
      onShowChange={(val) => setIsShowing(val)}
    >
      <CSidebarBrand className="d-md-down-none" to="/home">
        <img
          className="c-sidebar-brand-full"
          style={{height: '35px'}}
          src={Images.logo5}
        />
        <img
          className="c-sidebar-brand-minimized"
          style={{height: '35px'}}
          src={Images.logo5}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default Layout2;
